import './theme.scss'
// import Vue from 'vue'

// Had some problems with emitting evenst from directives, solution here:
// https://stackoverflow.com/questions/49264426/vuejs-custom-directive-emit-event
// export const IntroText = {}
export const Title = {}

// Vue.directive('introtext', IntroText)
// Vue.directive('title', Title)

export const IntroText = {
  beforeMount: function (el: HTMLElement, binding: any) {
    const eventName = 'my-event'
    const eventData = { myData: 'stuff - ' + binding.expression }

    let i = 0
    const txt = el.innerText
    el.innerText = ''
    function typeWriter () {
      if (i < txt.length) {
        el.innerText = txt.substring(0, i)
        i++
        setTimeout(typeWriter, txt.charAt(i - 2) === '.' ? 800 : 50)
      } else {
        el.dispatchEvent(new CustomEvent(eventName, { detail: eventData }))
      }
    }
    typeWriter()
  }
}
