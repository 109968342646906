
import { defineComponent } from 'vue'
import { HTTP } from '../plugins/http-common'

export default defineComponent({
  data () {
    return {
      modalOpen: false
    }
  },
  created () {
    HTTP.get('/game/gamerunning')
      .then((response) => {
        // If the game is still on, or even continued, show the option to resume
        if (response.data !== -1) {
          this.showModal()
          this.$store.commit('switchTheme', response.data)
        }
      })
      .catch((error) => {
        console.log('no game' + error)
      })
  },
  methods: {
    showModal () {
      this.modalOpen = true
    },
    hideModal () {
      this.modalOpen = false
    },
    stopGame () {
      this.$store.dispatch('stopGame')
        .then(() => {
          this.$router.push({ path: '/' })
          this.hideModal()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    resumeGame () {
      this.$router.push({ path: '/countdown' })
      this.hideModal()
    }
  }
})
