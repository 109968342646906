
import { mythemes } from '@/themes'
import { defineComponent } from 'vue'
import { ThemeAudio } from '../themes/hacker/theme'

export default defineComponent({

  data: function () {
    return {
      audio: ThemeAudio,
      show: true,
      showStart: false,
      routeTo: ''
    }
  },
  created () {
    this.audio.intro_text.loop = true
    this.audio.intro_text.play()
  },
  unmounted () {
    this.audio.intro_text.pause()
  },
  computed: {
    activeTheme () { return mythemes.get(this.$store.state.currentThemeId) }
  },
  methods: {
    stopGame () {
      this.routeTo = '/' // store the route for after the animation
      this.show = false
      this.showStart = false
    },
    stopAudio () {
      this.audio.intro_text.pause()
    },
    afterLeave () {
      this.$router.push({ path: this.routeTo }) // route
    },
    startGame () {
      this.routeTo = '/countdown' // store the route for after the animation
      this.show = false
      this.showStart = false
    },
    isDebug () {
      return process.env.VUE_APP_DEBUG === 'true'
    }
  }
})
