import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view")
  const _component_resume_play = _resolveComponent("resume-play")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", {
      style: { 'background-image': 'url(' + _ctx.activeTheme.backgroundimage + ')',
    'background-color': _ctx.activeTheme.backgroundcolor },
      id: "theme"
    }, [
      _createVNode(_component_router_view)
    ], 4),
    _createVNode(_component_resume_play)
  ], 64 /* STABLE_FRAGMENT */))
}