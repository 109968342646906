
import { defineComponent } from 'vue'
import { mythemes } from '../themes/'

export default defineComponent({
  name: 'Home',
  data () {
    return {
      show: true
    }
  },
  computed: {
    activeTheme () { return mythemes.get(this.$store.state.currentThemeId) },
    allThemes () { return [...mythemes.values()] }
  },
  methods: {
    switchTheme (index) { this.$store.commit('switchTheme', index) },
    startGame: function () {
      this.show = false
      this.$router.push({ path: 'intro' })
    },
    afterLeave: function () {
      this.$router.push({ path: 'intro' })
    }
  }
})
