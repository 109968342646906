
import { HTTP } from '../plugins/http-common'
import transitionToPromise from '../transitionpromise'
import { ThemeAudio } from '../themes/hacker/theme'
import { defineComponent } from 'vue'

interface WS {
  setInterval(b: Function, a: number): Function;
  clearInterval(a: number): Function;
  terminate(): Function;
  onmessage(): unknown;
}
const secondWorker: WS | undefined = new Worker('../timer.worker.js', { type: 'module' }) as unknown as WS

export default defineComponent({
  data: () => ({
    remainingSeconds: 0,
    penaltySeconds: 30,
    solutionLength: 0,
    maxTimeInMinutes: 0,
    gameState: 0,
    code: '',
    addSeconds: 0,
    timeout: false,
    passed: false,
    failed: false,
    errorMessage: '',
    emptyanswer: false,
    audio: ThemeAudio,
    show: false
  }),
  created () {
    this.$store.dispatch('startGame')
      .then(response => {
        this.remainingSeconds = response.data.remainingSeconds
        this.penaltySeconds = response.data.penaltySeconds
        this.solutionLength = response.data.solutionLength
        this.maxTimeInMinutes = response.data.maxTimeInMinutes
        this.gameState = response.data.state

        this.timeout = this.remainingSeconds < 0
        // Do not say game started on refresh
        if (this.remainingSeconds === this.maxTimeInMinutes * 60) {
          this.audio.game_started.play()
        }
        this.show = true
        this.startTimer()
      })
      .catch(error => {
        this.errorMessage = 'Kan spel niet starten'
        console.log(error)
      })
  },
  unmounted () {
    this.audio.alert.pause()
    this.audio.beep.pause()
    this.resetTimer()
  },
  methods: {
    startTimer: function () {
      if (secondWorker !== undefined) {
        // secondWorker = new TimerWebWorker()
        secondWorker.onmessage = () => {
          this.countdown()
        }
      }
    },
    stopTimer: function () {
      if (secondWorker !== undefined) {
        secondWorker.onmessage = () => null
        // secondWorker.terminate()
        // secondWorker = undefined
      }
    },
    resetTimer: function () {
      this.remainingSeconds = 30 * 60
      if (secondWorker !== undefined) {
        secondWorker.onmessage = () => null
        // secondWorker.terminate()
        // secondWorker = undefined
      }
    },
    setTimeTo: function (seconds) {
      this.remainingSeconds = seconds
      // clearInterval(this.timer)
      this.countdown()
    },
    padTime: function (time) {
      return (time < 10 ? '0' : '') + time
    },
    setOutcome: function (passed) {
      // gamestate: 0=Started,1=Passed,2=Continued,3=Stopped
      this.passed = passed
      this.failed = !passed
    },
    doPenalty: function () {
      if (this.addSeconds > 0) {
        this.addSeconds--
        this.timeout ? this.remainingSeconds++ : this.remainingSeconds--
        setTimeout(() => {
          this.doPenalty()
        }, 10 * this.penaltySeconds - (this.penaltySeconds - this.addSeconds) * 8)
      }
    },
    countdown: function () {
      if (this.remainingSeconds === 0 && !this.timeout) {
        this.audio.game_over.play()
        this.timeout = true
        this.setOutcome(false)
      }

      this.timeout ? this.remainingSeconds++ : this.remainingSeconds--

      if (this.remainingSeconds <= 10 && !this.timeout) {
        this.audio.beep.play()
      }

      if (
        this.remainingSeconds < 63 &&
        this.remainingSeconds > 60 &&
        !this.timeout
      ) {
        this.audio.beep.play()
      }

      if (this.remainingSeconds === 60 && !this.timeout) {
        this.audio.one_minute_remaining.play()
      }
    },
    continueGame: function () {
      this.$store.dispatch('continueGame')
      this.gameState = 2 // Continued
    },
    checkCode: async function () {
      if (this.code.length < this.solutionLength) {
        this.emptyanswer = true
        return
      }
      const enter = document.getElementById('enter') as HTMLElement
      const finishedPromise = transitionToPromise(enter, 'opacity', '0')
      await finishedPromise
      HTTP.post('/game/checkanswer', `"${this.code}"`, {
        headers: { 'Content-Type': 'application/json' }
      })
        .then(response => {
          if (response.data.code === 42) {
            this.setOutcome(true)
            this.stopTimer()
          } else {
            this.addSeconds += this.penaltySeconds
            this.doPenalty()
            this.audio.alert.play()
            this.code = '' // empty code
          }
          transitionToPromise(enter, 'opacity', '1')
        })
        .catch(error => console.log(error))
    },
    afterLeave: function () {
      this.$store.dispatch('stopGame')
        .then(() => {
          this.$router.push({ path: '/' })
        })
        .catch(error => {
          this.errorMessage = 'Kan het spel niet stoppen'
          // this.$router.push({ path: "/" });
          console.log(error)
        })
    },
    stopGame: function () {
      this.show = false
    },
    continuePlaying: function () {
      this.failed = false
    }
  },
  computed: {
    minutes (): string {
      if (this.remainingSeconds === 0) {
        return '--'
      }
      const minutes = Math.floor(Math.abs(this.remainingSeconds) / 60)
      return (minutes < 10 ? '0' : '') + minutes
    },
    seconds () {
      if (this.remainingSeconds === 0) {
        return '--'
      }
      const seconds = Math.floor(Math.abs(this.remainingSeconds) - Math.abs(+this.minutes) * 60)
      return (seconds < 10 ? '0' : '') + seconds
    },
    isDebug () {
      return process.env.VUE_APP_DEBUG === 'true'
    }
  }
})
