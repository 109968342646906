import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { store } from './store'

// Import thema
// import '@/themes/hacker/theme.scss'
// import { Title, IntroText } from '@/themes/hacker/theme.ts'

import '@/themes/basic/theme.scss'
import { Title, IntroText } from '@/themes/basic/theme'

// FIXME: Vue3
// import VueBrowserUpdate from 'vue-browserupdate'
// import cookieconsent from 'vue-cookieconsent-component'

/* UGLY POLYFILL FOR IE11 CUSTOM EVENTS CAUSE VUE DOES NOT SUPPORT EVENTS FROM DIRECTIVES WELL */
// (function () {
//   if (typeof window.CustomEvent === 'function') return false // If not IE

//   function CustomEvent (event, params) {
//     params = params || { bubbles: false, cancelable: false, detail: undefined }
//     const evt = document.createEvent('CustomEvent')
//     evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
//     return evt
//   }

//   CustomEvent.prototype = window.Event.prototype

//   window.CustomEvent = CustomEvent
// })()

// Vue.component('cookie-consent', cookieconsent)
// Vue.config.productionTip = false
// Vue.use(VueBrowserUpdate, {
//   options: {
//     required: { i: 8, f: 25, o: 17, s: 9, c: 22 },
//     reminder: 24
//   },
//   test: false,
//   l: false
// })

const app = createApp(App)
app.use(store).use(router).mount('#app')

app.directive('introtext', IntroText)
app.directive('title', Title)
