
import { defineComponent } from 'vue'
import ResumePlay from './components/resumeplay.vue'
import { mythemes } from './themes'

export default defineComponent({
  components: {
    ResumePlay
  },
  computed: {
    activeTheme () {
      return mythemes.get(this.$store.state.currentThemeId)
    }
  }
})
