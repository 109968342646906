
export type Theme = {
  id: number;
  title: string;
  shorttitle: string;
  subtitle: string;
  backgroundimage: string;
  backgroundcolor: string;
  underconstruction: boolean;
  intro: {
    font: string;
    text: string;
    name: string;
  };
  countdown: {
  };
}

export const mythemes: Map<number, Theme> = new Map()

mythemes.set(1, {
  id: 1,
  title: 'Gehackt',
  shorttitle: 'Gehackt',
  subtitle: 'Het schoolsysteem is gehackt!',
  backgroundimage: '"/img/hacker.jpg"',
  backgroundcolor: '',
  underconstruction: false,
  intro: {
    font: '',
    text: `Dit systeem is gehackt en alle persoonlijke documenten zijn versleuteld.
    Om ze terug te krijgen moet er een codewoord worden ingevoerd.
    De letters van dat codewoord moeten eerst verdiend worden met opdrachten.
    Als het codewoord op tijd ingevoerd wordt, worden alle persoonlijke documenten weer vrijgegeven.
    Maar pas op: met elke foute invoer gaat er tijd vanaf..`,
    name: '-H4ck3r-'
  },
  countdown: {
    font: ''
  }
})

mythemes.set(3, {
  id: 3,
  title: 'Laat je stem horen',
  shorttitle: 'Stemmen',
  subtitle: 'Stem jij ook?',
  backgroundimage: '"/img/stemmen.jpg"',
  underconstruction: false,
  backgroundcolor: '',
  intro: {
    font: '',
    text: `Beste leerlingen, 
 
    De schoolverkiezingen voor de leerlingenraad worden gesaboteerd. De automatische stemmenteller verandert de stemmen in het voordeel van één van de verkiesbare leerlingen.  
    De dader wil haar geheime codewoord om de sabotage te stoppen niet met mij delen. Gelukkig heb ik aantekeningen gevonden waarmee jullie de letters voor het codewoord kunnen vinden.  
    Kunnen jullie het juiste codewoord voor mij vinden om de stemmenteller te stoppen? 
    Maar pas op: met elke foute invoer gaat er tijd vanaf.
    `,
    name: 'De verkiezingsvoorzitter'
  },
  countdown: {
    font: ''
  }
})

mythemes.set(2, {
  id: 2,
  title: 'Met alle respect',
  shorttitle: 'Respect',
  subtitle: 'Samen tegen haat!',
  backgroundimage: '"/img/respect.jpg"',
  underconstruction: true,
  backgroundcolor: '',
  intro: {
    font: '',
    text: `Beste leerlingen,

    Er worden haatberichten verspreid op ons schoolforum. Wij accepteren dit niet en willen een haatfilter op het forum installeren. Kunnen jullie ons hiermee helpen? Je hebt hiervoor een codewoord nodig.
    De letters van dat codewoord moeten eerst verdiend worden met opdrachten. Als je het codewoord op tijd invoert, kan het haatfilter aangezet worden.
    Maar pas op: met elke foute invoer gaat er tijd vanaf.
    Gaat het jullie lukken?
    
    Veel succes,
    `,
    name: 'De schoolleiding'
  },
  countdown: {
    font: ''
  }
})
