export default (el: HTMLElement, property: string, value: string) =>
  new Promise(resolve => {
    el.style[property as never] = value
    const transitionEnded = (e: TransitionEvent) => {
      if (e.propertyName !== property) return
      el.removeEventListener('transitionend', transitionEnded)
      resolve()
    }
    el.addEventListener('transitionend', transitionEnded)
  })
