import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "h-100 d-flex flex-column p-2 p-sm-3 p-md-4" }
const _hoisted_2 = {
  key: 0,
  class: "window-frame",
  style: {"min-height":"14rem"}
}
const _hoisted_3 = { class: "flex-even d-flex align-items-end" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_title = _resolveDirective("title")
  const _directive_introtext = _resolveDirective("introtext")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_Transition, {
      appear: "",
      "appear-active-class": "animated zoomIn",
      "leave-active-class": "animated zoomOut"
    }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createBlock("button", {
              key: 0,
              class: "position-absolute right-0 top-0 m-2 m-sm-3 m-md-4 button-small",
              onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.stopGame(...args)))
            }, "STOP"))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, {
      appear: "",
      "appear-active-class": "animated zoomIn",
      "leave-active-class": "animated zoomOut",
      "onAfter-leave": _ctx.afterLeave
    }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createBlock("div", _hoisted_2, [
              _withDirectives(_createVNode("h4", { class: "d-inline-block p-2 bg-white h4 clear-all clearfix" }, _toDisplayString(_ctx.activeTheme.title), 513), [
                [_directive_title]
              ]),
              _withDirectives(_createVNode("div", {
                class: "mt-2 py-1 px-2 bg-white",
                "onMy-event": _cache[2] || (_cache[2] = $event => {_ctx.stopAudio(); _ctx.showStart=true})
              }, _toDisplayString(_ctx.activeTheme.intro.text), 545), [
                [_directive_introtext]
              ]),
              (_ctx.showStart)
                ? _withDirectives((_openBlock(), _createBlock("div", {
                    key: 0,
                    class: "d-inline-block mt-2 py-1 px-2 bg-white mt-2"
                  }, _toDisplayString(_ctx.activeTheme.intro.name), 513)), [
                    [_directive_title, { time: 500 }]
                  ])
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["onAfter-leave"]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_Transition, {
        "enter-active-class": "animated zoomIn",
        "leave-active-class": "animated zoomOut"
      }, {
        default: _withCtx(() => [
          (_ctx.showStart)
            ? (_openBlock(), _createBlock("button", {
                key: 0,
                class: "button-large ml-auto",
                onClick: _cache[3] || (_cache[3] = (...args) => (_ctx.startGame(...args)))
              }, "Start"))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}