import { HTTP } from '@/plugins/http-common'
import { createStore } from 'vuex'

export const state = {
  currentThemeId: 1
}

export type State = typeof state;

export const store = createStore({
  state,
  mutations: {
    switchTheme (state, index) {
      state.currentThemeId = index
    }
  },
  actions: {
    async stopGame () {
      await HTTP.post('/game/stop')
    },
    async startGame ({ state }) {
      return await HTTP.get('/game/gamerunning').then(async response => {
        if (response.data !== -1) {
          store.commit('switchTheme', response.data)
          return await HTTP.post(`/game/start/${response.data}`)
        } else {
          return await HTTP.post(`/game/start/${state.currentThemeId}`)
        }
      })
    },
    async continueGame () {
      await HTTP.post('/game/continue')
    }
  }
})
